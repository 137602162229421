var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "saveButton" },
    [
      _c(
        "div",
        { attrs: { title: _vm.tooltipText } },
        [
          _c("CButton", {
            directives: [
              {
                name: "t",
                rawName: "v-t",
                value: "forms.common.save",
                expression: "'forms.common.save'"
              }
            ],
            attrs: {
              color: "primary",
              disabled: !_vm.canBeSaved,
              "data-testid": "save"
            },
            on: { click: _vm.internalSave }
          })
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("forms.common.error_message"),
            color: "warning",
            show: _vm.showInvalidModal,
            centered: true,
            closeOnBackdrop: true
          },
          on: {
            "update:show": function($event) {
              _vm.showInvalidModal = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "forms.common.ok",
                        expression: "'forms.common.ok'"
                      }
                    ],
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        _vm.showInvalidModal = false
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("CIcon", { attrs: { name: "cilWarning", size: "3xl" } }),
          _c("br"),
          _c("div", { staticClass: "errorMessage" }, [
            _vm._v(_vm._s(_vm.$t("forms.common.invalid_error")))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }